import {BaseRequest} from "@/request/BaseRequest";

class openApi extends BaseRequest {

    /**
     * 获取三方平台信息
     */
    authorizationPageInfo() {
        return this.get('/wxOpen/authorizationPageInfo');
    }

}

const c = new openApi();
export {c as openApi};