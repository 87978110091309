


















import {Vue, Component} from "vue-property-decorator";
import {openApi} from "@/request/open/OpenApi";

@Component({
    name: 'open_auth_address'
})
export default class open_auth_address extends Vue {
    appId: string = 'wx02e5e5c509bff5b3';
    url: string = '';
    generateAuthUrl(row: any) {
        this.getAuthorizationPageInfo().then((data: any) => {
            // this.url = `https://mp.weixin.qq.com/safe/bindcomponent?action=bindcomponent&auth_type=3&no_scan=1&component_appid=${data.componentAppId}&pre_auth_code=${data.preAuthCode}&redirect_uri=${data.redirectUri}&biz_appid=${this.appId}#wechat_redirect`;
            let redirectUri: string = 'http://plat.cdslhd.com/result_auth';
            this.url = `https://mp.weixin.qq.com/safe/bindcomponent?action=bindcomponent&auth_type=3&no_scan=1&component_appid=${data.componentAppId}&pre_auth_code=${data.preAuthCode}&redirect_uri=${redirectUri}&biz_appid=${this.appId}#wechat_redirect`;
        })
    }
    registerMicro() {
        this.getAuthorizationPageInfo().then((data: any) => {
            let redirectUrl: string = `http://plat.cdslhd.com/register_micro@${this.appId}`;
            this.url = `https://mp.weixin.qq.com/cgi-bin/fastregisterauth?appid=${this.appId}&component_appid=${data.componentAppId}&copy_wx_verify=1&redirect_uri=${encodeURI(redirectUrl)}`;
        })
    }
    getAuthorizationPageInfo() {
        return new Promise(resolve => {
            this.$Message.loading({
                content: '请稍后...',
                duration: 0
            });
            openApi.authorizationPageInfo().then((body: any) => {
                this.$Message.destroy();
                this.$Message.info('生成成功');
                if (body.code === 0) {
                    resolve(body.data);
                }
            })
        })
    }
    doCopy() {
        this.$copyText(this.url).then(() => {
            this.$Message.info('复制成功');
        })
    }
}

