import { render, staticRenderFns } from "./AuthAddress.vue?vue&type=template&id=4bd8254b&scoped=true&"
import script from "./AuthAddress.vue?vue&type=script&lang=ts&"
export * from "./AuthAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd8254b",
  null
  
)

export default component.exports